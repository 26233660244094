import { useState } from 'react';
import moment from 'moment';
import useTranslations from '../../hooks/useTranslations';
import { ActionTypes, useContextState } from '../../redux/contextState';
import { refreshToken } from '../../utils/refreshToken';
import isComplete from '../../utils/steps/isComplete';
import useCompleteProfileModal from '../../hooks/useCompleteProfileModal/useCompleteProfileModal';
import InfoIcon from '../../assets/InfoIcon';
import { getScreeningInvitation, hideDeletedInvitation, saveVideoWatchedDate } from '../../api/userService';
import PrefilterTest from '../PrefilterTest/PrefilterTest';
import ModalCustom from '../Modal/Modal';
import useVideoModal from '../../hooks/useVideoModal';
import VideoModal from '../VideoModal/VideoModal';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { PlayCircleOutlined } from '@ant-design/icons';
import { isNullOrUndefined } from '../../utils/typesUtils';
import { userUtcOffset, validateInvitationHasLimitDate, validateInvitationStatus } from '../../utils/calendarUtils';

const ScreeningInvitationCard = ({
  company,
  stage,
  userId,
  levelId,
  id,
  technologyId,
  companyId,
  deletedByPartner,
  workspaceId,
  prefilterScore,
  welcomeVideoDate,
  limitDate,
}) => {
  const { startDate, endDate } = stage;
  const [loadingRedirect, setLoadingRedirect] = useState(false);
  const { t } = useTranslations();
  const { contextState, setContextState } = useContextState();
  const [showPrefilterModal, setShowPrefilterModal] = useState(false);
  const [message, setMessage] = useState();
  const [showMessage, setShowMessage] = useState();
  const { openLinkModal, closeModal } = useVideoModal();

  const { renderCompleteProfileModal } = useCompleteProfileModal();

  const momentStartDate = moment(startDate).utc(userUtcOffset());
  const momentEndDate = moment(
    new Date(limitDate) < new Date(endDate) && !isNullOrUndefined(limitDate) ? limitDate : endDate,
  ).utc(userUtcOffset());

  const formattedStartHour = momentStartDate.format('HH:mm');
  const formattedEndHour = momentEndDate.format('HH:mm');

  const startDay = momentStartDate.format('DD/MM/Y');
  const endDay = momentEndDate.format('DD/MM/Y');

  const isButtonDisabled = new Date(startDate).getTime() > new Date().getTime();

  const getScreeningsUrl = async invitationId => {
    const isProctoringEnable = stage?.proctoringEnabled;
    const proctoringKey = window?.location?.hash?.substring(1);
    const redirectURL = new URL(process.env.RAZZLE_RUNTIME_SCREENINGS);
    const token = await refreshToken();
    redirectURL.search = new URLSearchParams({
      token: token,
      userId: userId,
      isProctoringEnable: isProctoringEnable,
    });
    if (levelId) {
      redirectURL.searchParams.append('levelId', levelId);
    }
    if (id) {
      redirectURL.searchParams.append('invitationId', invitationId ?? id);
    }
    if (technologyId) {
      redirectURL.searchParams.append('technologyId', technologyId);
    } else {
      redirectURL.searchParams.append('workspaceId', workspaceId);
      redirectURL.searchParams.append('companyId', companyId);
    }
    if (proctoringKey) {
      redirectURL.hash = `#${proctoringKey}`;
    }
    return redirectURL;
  };

  const handleStartScreening = async invitationId => {
    if (!isButtonDisabled) {
      let redirectUrl;
      if (stage?.requireCompletedProfile) {
        if (isComplete(contextState?.profile) < 2) {
          renderCompleteProfileModal(redirectUrl);
        } else {
          redirectUrl = await getScreeningsUrl(invitationId);
        }
      } else {
        redirectUrl = await getScreeningsUrl(invitationId);
      }
      if (redirectUrl) {
        setLoadingRedirect(false);
        window.location.href = redirectUrl.toString();
      }
    }
  };

  const handleStartWithVideo = async invitationId => {
    try {
      await saveVideoWatchedDate(invitationId);
      closeModal();
    } catch (e) {}
    handleStartScreening(invitationId);
  };

  const handleRefreshResult = () => {
    window.location.href = '/home';
  };

  const handleStepsBeforeStart = () => {
    setLoadingRedirect(true);
    if (stage?.prefilterQuestionId && !prefilterScore) {
      setShowPrefilterModal(true);
      setLoadingRedirect(false);
    } else if (stage?.welcomeVideoUrl && !welcomeVideoDate) {
      setLoadingRedirect(false);
      openLinkModal({ url: stage.welcomeVideoUrl, invitationId: id });
    } else {
      handleStartScreening();
    }
  };

  const handleShowMessage = async message => {
    if (message === 'ok') {
      prefilterScore = 1;
      handleStepsBeforeStart();
    } else {
      setShowPrefilterModal(false);
      setMessage(message);
      setShowMessage(true);
    }
  };

  return (
    <div
      className='flex flex-col mx-1.5 my-4 border-2 rounded-lg border-grey p-3  w-[320px] min-w-[300px] bg-white  dark:bg-dark-normal dark:border-gray-500'
      data-testid='invitations-cards-container-div'>
      <PrefilterTest
        showModal={showPrefilterModal}
        setShowModal={setShowPrefilterModal}
        testId={stage?.prefilterQuestionId}
        invitationId={id}
        company={company}
        handleResults={handleShowMessage}
      />
      <ModalCustom
        setShow={setShowMessage}
        show={showMessage}
        onOkText={t('PREFILTER_CONTINUE')}
        onOk={handleRefreshResult}
        title={company ? company.displayName : ''}
        content={message}
      />
      <VideoModal action={handleStartWithVideo} invitationId={id} />
      {company && (
        <div className='flex w-full justify-between items-center'>
          <span className='italic dark:text-white' data-testid='invitations-cards-title-span'>
            {company?.displayName}{' '}
            {deletedByPartner || validateInvitationStatus(endDate, limitDate)
              ? ''
              : `${t('SCREENINGS_WANTS_TO_EVALUATE_YOU_IN')} :`}
          </span>
          {deletedByPartner || validateInvitationStatus(endDate, limitDate) ? (
            <span className='text-alkemy-blue'>
              <InfoIcon />
            </span>
          ) : (
            company?.picture && (
              <img
                src={company?.picture}
                className='max-w-[45px] max-h-11 ml-2.5'
                data-testid='invitations-cards-company-img'
              />
            )
          )}
        </div>
      )}
      <div className='font-medium text-sm text-grey'>
        <span
          className='inline-block mb-4 text-alkemy-black text-sm font-semibold dark:text-white break-word'
          data-testid='invitations-cards-name-span'>
          {stage?.recruitSession?.name}
        </span>

        {deletedByPartner ? (
          <div>
            <p className='block text-xs text-black dark:text-white' data-testid='invitations-cards-delete-invitation-p'>
              {t('DELETED_SCREENING_INVITATION')}
            </p>
          </div>
        ) : validateInvitationStatus(endDate, limitDate) ? (
          <div>
            <p className='block text-xs text-black dark:text-white'>{t('LIMIT_DATE_FINISHED_SCREENING_INVITATION')}</p>
          </div>
        ) : (
          validateInvitationHasLimitDate(endDate, limitDate) && (
            <>
              <p className='block dark:text-white' data-testid='invitations-cards-start-date-p'>
                {`${t('SCREENINGS_INVITATION_FROM')} `}
                <span className='text-purple' data-testid='invitations-cards-start-date-span'>
                  {startDay} {`${t('SCREENINGS_INVITATION_AT_START_END')} `} {formattedStartHour}
                </span>
              </p>
              <p className='block dark:text-white' data-testid='invitations-cards-end-date-p'>
                {`${t('SCREENINGS_INVITATION_UNTIL')} `}
                <span className='text-purple' data-testid='invitations-cards-end-date-span'>
                  {endDay} {`${t('SCREENINGS_INVITATION_AT_START_END')} `}
                  {formattedEndHour}
                </span>
              </p>
            </>
          )
        )}
      </div>
      {deletedByPartner || validateInvitationStatus(endDate, limitDate) ? (
        <button
          onClick={() => {
            hideDeletedInvitation({ id })
              .then(async () => {
                getScreeningInvitation().then(res => {
                  setContextState({
                    type: ActionTypes.SetScreenings,
                    value: res,
                  });
                });
              })
              .catch(() => {});
          }}
          disabled={isButtonDisabled}
          className='w-fit px-5 py-1 m-auto mt-5 bg-alkemy-blue font-bold rounded-2xl text-white hover:cursor-pointer'
          data-testid='invitations-cards-delete-invitation-btn'>
          {t('DELETED_SCREENING_INVITATION_DO_NOT_SHOW')}
        </button>
      ) : loadingRedirect ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 20, marginTop: '1em', color: 'maya-blue' }} spin />} />
      ) : (
        <div className='flex flex-wrap justify-between'>
          <button
            onClick={handleStepsBeforeStart}
            disabled={isButtonDisabled}
            className='w-fit px-5 py-1 m-auto mt-5 bg-maya-blue font-bold rounded-2xl text-white hover:cursor-pointer disabled:bg-grey disabled:cursor-not-allowed'
            data-testid='invitations-cards-start-evaluation-button'>
            {isButtonDisabled ? t('SCREENING_COMING_SOON') : t('SCREENING_START')}
          </button>
          {stage?.welcomeVideoUrl && welcomeVideoDate && (
            <div className='mt-5' title={t('SEE_WELCOME_VIDEO_TITLE')}>
              <PlayCircleOutlined
                className='mt-4'
                onClick={() => openLinkModal({ url: stage.welcomeVideoUrl, invitationId: id })}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ScreeningInvitationCard;
