import moment from 'moment';
import useTranslations from '../../hooks/useTranslations';
import { userUtcOffset } from '../../utils/calendarUtils';

const FinishedScreeningCard = ({ id, company, stage, endDate }) => {
  const { t } = useTranslations();

  const momentEndDate = moment(endDate).utc(userUtcOffset());
  const endDay = momentEndDate.format('DD/MM/Y');

  return (
    <div
      key={id}
      className='flex flex-col my-4 mx-2 border-2 rounded-lg border-alkemy-blue px-3 pt-4 pb-6  w-[20rem] min-w-[16.875rem] bg-white dark:bg-dark-normal dark:border-gray-500'
      data-testid='finish-screening-cards-container-div'>
      {company && (
        <div className='flex w-full justify-between items-center mb-2'>
          <span
            className='finished-screening-name line-clamp-2 capitalize inline-block text-alkemy-black text-sm font-semibold dark:text-white break-word'
            data-testid='finish-screening-cards-name-span'>
            {stage?.recruitSession?.name}
          </span>
          {company?.picture && (
            <img
              src={company.picture}
              className='w-[2.813rem] h-[2.813rem] ml-3 object-contain'
              data-testid='finish-screening-cards-company-img'
            />
          )}
        </div>
      )}
      <div className='mt-2.5 font-medium text-sm text-grey flex'>
        <p className='block dark:text-white' data-testid='finish-screening-cards-status-p'>
          {t('FINISHED_SCREENING_STATUS_TITLE')}
          <span className='font-bold text-alkemy-black' data-testid='finish-screening-cards-status-span'>
            {endDate ? t('FINISHED_SCREENING_STATUS_SUBMITTED') : t('FINISHED_SCREENING_STATUS_EXPIRED')}
          </span>
        </p>
      </div>
      <div className='font-medium text-sm text-grey mt-2.5 flex'>
        <p className='block dark:text-white' data-testid='finish-screening-cards-submission-p'>
          {t('FINISHED_SCREENING_SUBMISSION_TITLE')}
          <span className='text-purple font-semibold' data-testid='finish-screening-cards-submission-span'>
            {endDate ? endDay : t('FINISHED_SCREENING_NO_END_DATE')}
          </span>
        </p>
      </div>
    </div>
  );
};

export default FinishedScreeningCard;
