export const DOCUMENT_TYPE_OPTIONS = [
  { name: 'CUIL', value: 'CUIL' },
  { name: 'CPF', value: 'CPF' },
  { name: 'NIT', value: 'NIT' },
  { name: 'RUT', value: 'RUT' },
  { name: 'CURP', value: 'CURP' },
  { name: 'DNI', value: 'DNI' },
];

export const seniorityLevelTypes = [
  { name: 'TRAINEE' },
  { name: 'JUNIOR' },
  { name: 'SEMI_SENIOR' },
  { name: 'SENIOR' },
  { name: 'LIDER' },
];
