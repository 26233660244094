import { isNullOrUndefined } from './typesUtils';

export function generateArrayOfDays(daysInMonth, callback) {
  const days = Array.from({ length: daysInMonth }, (_, num) => num + 1);
  const prevDays = Array.from({ length: 31 }, (_, num) => num + 1);

  return !days.length ? callback(prevDays) : callback(days);
}

export function generateArrayOfYears() {
  const max = new Date().getFullYear();
  const min = max - 80;
  const years = [];

  for (let i = max; i >= min; i--) {
    years.push({ id: i, name: i });
  }
  return years;
}

export function validateInvitationHasLimitDate(endDate, limitDate) {
  return (
    new Date(endDate) < new Date(9990, 1, 1) ||
    (new Date(limitDate) < new Date(9990, 1, 1) && !isNullOrUndefined(limitDate))
  );
}

export function userUtcOffset() {
  return -new Date().getTimezoneOffset() / 60;
}

export function validateInvitationStatus(endDate, limitDate) {
  return (
    !isNullOrUndefined(limitDate) &&
    !isNullOrUndefined(endDate) &&
    (new Date(limitDate) < new Date() || new Date(endDate) < new Date())
  );
}
