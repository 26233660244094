import React, { useEffect, useState } from 'react';
import Banner from '../../components/Banner/Banner';
import moment from 'moment';
import { ActionTypes, useContextState } from '../../redux/contextState';
import useTranslations from '../../hooks/useTranslations';
import Container from '../../components/Container/Container';
import Challenges from './Challenges/Challenges';
import DevProfile from '../../components/DevProfile/DevProfile';
import MyChallenges from './MyChallenges/MyChallenges';
import { queryToObject } from '../../utils/processQueryString';
import { useHistory } from 'react-router-dom';
import './home.css';
import {
  acceptPendingInvitations,
  createAcceptedInvitation,
  getProfileChallenges,
  getScreeningInvitation,
  getUserHasPendingInvitations,
  postScreeningInvitation,
} from '../../api/userService';
import ScreeningInvitations from '../../components/ScreeningInvitations/ScreeningInvitations';
import StartedScreenings from '../../components/StartedScreenings/StartedScreenings';
import { Skeleton, Tooltip } from 'antd';
import ModalCustom from '../../components/Modal/Modal';
import { AcceptAssessmentInvitationStatus } from '../../utils/constants';
import FinishedScreenings from './FinishedScreenings/FinishedScreenings';
import useRoles from '../../hooks/useRoles.js';
import { roles } from '../../constants/enums';
import useCompleteProfileModal from '../../hooks/useCompleteProfileModal/useCompleteProfileModal.js';
import { userUtcOffset } from '../../utils/calendarUtils.js';
const Home = () => {
  const { contextState, setContextState } = useContextState();
  const { t } = useTranslations();
  const languageKey = contextState.ux.language;
  const [loading, setLoading] = useState(!contextState?.profileChallenges?.challenges?.length > 0);
  const { screenings } = contextState;
  const { isRole } = useRoles();

  const [showAssessmentInvitationsModal, setShowAssessmentInvitationsModal] = useState(
    contextState.acceptedAssessmentInvitations.length,
  );
  const [showInvitationModal, setShowInvitationModal] = useState(false);
  const [textModal, setTextModal] = useState();
  const [subtitleModal, setSubtitleModal] = useState();

  const { renderCompleteProfileModal } = useCompleteProfileModal();

  const formatAssessmentInvitationTexts = invitation => {
    return `${t(`ASSESSMENT_INVITATION_STATUS_${invitation.invitationStatus}`).replace(
      '{recruitSessionStageName}',
      invitation?.recruitSessionStage?.name,
    )} ${
      new Date(invitation?.recruitSessionStage?.endDate) < new Date(9990, 1, 1)
        ? t(`ASSESSMENT_INVITATION_LIMIT_DATE`).replace(
            '{limitDate}',
            moment(invitation?.recruitSessionStage?.endDate)
              .utc(userUtcOffset())
              .locale(languageKey)
              .format('D [de] MMMM [de] YYYY'),
          )
        : ''
    }`;
  };

  const history = useHistory();

  const dueDateDefinition = invitation => {
    const isDefined = value => value !== null && value !== undefined;

    const chosenDate =
      isDefined(invitation?.limitDate) &&
      isDefined(invitation?.recruitSessionStageEndDate) &&
      new Date(invitation.limitDate) < new Date(invitation.recruitSessionStageEndDate)
        ? invitation.limitDate
        : invitation?.recruitSessionStageEndDate;

    const momentEndDate = moment(chosenDate).utc(userUtcOffset());
    if (momentEndDate >= new Date(9990, 1, 1)) {
      return false;
    }
    return momentEndDate.locale(languageKey).format('D [de] MMMM [de] YYYY');
  };

  const acceptEmailInvitations = async () => {
    try {
      await postScreeningInvitation().then(async res => {
        if (res.length) {
          setShowInvitationModal(true);
          setTextModal(
            t(`${res.length === 1 ? 'ACCEPT_AUTOMATICALLY_INVITATION' : 'ACCEPT_AUTOMATICALLY_INVITATIONS'}`),
          );
          setSubtitleModal(
            <ul className='text-left pt-2 ml-28 list-disc'>
              {res.map(invitation => (
                <li className='my-1' key={invitation.invitationId}>
                  {`${invitation.recruitSessionStageName} - ${invitation.companyName} ${
                    dueDateDefinition(invitation) ? `${t('DUE_DATE')} ${dueDateDefinition(invitation)}` : ''
                  }`}
                </li>
              ))}
            </ul>,
          );
        }
        await getScreeningInvitation().then(res => {
          setContextState({
            type: ActionTypes.SetScreenings,
            value: res,
          });
        });
      });
    } catch (error) {
      if (error.response.data.statusCode === 401 || error.response.data.statusCode === 404) {
        setShowInvitationModal(true);
        setTextModal(t('ACCEPT_INVITATION_ERROR'));
      }
    }
  };

  const acceptLinkInvitations = async () => {
    let userHasPendingInvitations = false;
    if (contextState.isVerify) {
      await getUserHasPendingInvitations().then(res => {
        if (res) {
          userHasPendingInvitations = res;
        }
      });
      if (userHasPendingInvitations) {
        await acceptPendingInvitations()
          .then(res => {
            setContextState({
              type: ActionTypes.SetAcceptedAssessmentInvitations,
              value: res,
            });
            setShowAssessmentInvitationsModal(true);
            if (
              res?.some(
                invitation => invitation?.invitationStatus === AcceptAssessmentInvitationStatus.ACCEPTED_SUCCESSFULLY,
              )
            ) {
              getScreeningInvitation().then(res => {
                setContextState({
                  type: ActionTypes.SetScreenings,
                  value: res,
                });
              });
            }
          })
          .catch(error => {
            if (error.response.data.statusCode === 401 || error.response.data.statusCode === 404) {
              setShowInvitationModal(true);
              setTextModal(t('ACCEPT_INVITATION_ERROR'));
            }
          });
      }
    }

    if (
      (queryToObject(history.location.search)?.recruitSessionStageId ||
        queryToObject(history.location.search)?.invitation) &&
      !userHasPendingInvitations
    ) {
      createAcceptedInvitation({
        firstName: contextState?.profile?.firstName,
        lastName: contextState?.profile?.lastName,
        email: contextState?.profile?.email,
        recruitSessionStageId: queryToObject(history.location.search)?.recruitSessionStageId,
        shortId: queryToObject(history.location.search)?.invitation,
        jobApplied: queryToObject(history.location.search)?.jobApplied,
      })
        .then(res => {
          setContextState({
            type: ActionTypes.SetAcceptedAssessmentInvitations,
            value: res,
          });
          if (
            res?.some(
              invitation => invitation?.invitationStatus === AcceptAssessmentInvitationStatus.ACCEPTED_SUCCESSFULLY,
            )
          ) {
            getScreeningInvitation().then(res => {
              setContextState({
                type: ActionTypes.SetScreenings,
                value: res,
              });
            });
          }
          setShowAssessmentInvitationsModal(true);
        })
        .catch(error => {
          if (error.response.data.statusCode === 401 || error.response.data.statusCode === 404) {
            setShowInvitationModal(true);
            setTextModal(t('ACCEPT_INVITATION_ERROR'));
          }
        });
    }
  };

  useEffect(async () => {
    const requests = [acceptLinkInvitations(), acceptEmailInvitations()];
    await Promise.all(requests);

    localStorage.removeItem('prevUrlLogin');
    history.replace({ search: '' });
  }, []);

  useEffect(async () => {
    if (!contextState?.profileChallenges?.challenges?.length > 0) {
      await getProfileChallenges().then(res => {
        setContextState({
          type: ActionTypes.SetProfileChallenges,
          value: { ...res, challenges: res?.challenges?.filter(res => res?.reviewedAt) },
        });
        setLoading(false);
      });
    }
  }, []);

  const handleCloseAssessmentModal = show => {
    setShowAssessmentInvitationsModal(show);
    setTimeout(() => {
      setContextState({
        type: ActionTypes.SetAcceptedAssessmentInvitations,
        value: [],
      });
    }, 500);
  };

  useEffect(() => {
    const incompleteJobRole = contextState?.profile?.jobRole?.error;
    const lastModalOpened = contextState.jobRoleUpdateErrorOn;
    if (incompleteJobRole && (lastModalOpened === null || new Date().getTime() - lastModalOpened >= 600000)) {
      //every 10 minutes that the user stays on the page, it will ask to update their profile
      renderCompleteProfileModal(incompleteJobRole);
      setContextState({
        type: ActionTypes.SetJobRoleUpdateReminder,
      });
    }
  }, [contextState?.profile?.jobRole]);

  return (
    <div className='relative w-full pb-5' data-testid='home-container-div'>
      <ModalCustom
        show={showInvitationModal}
        setShow={setShowInvitationModal}
        onOkText={t('MODAL_BUTTON_AGREED')}
        onOk={() => setShowInvitationModal(false)}
        title={textModal ?? ''}
        subtitle={subtitleModal ?? ''}
      />
      <ModalCustom
        show={showAssessmentInvitationsModal}
        title={
          contextState.acceptedAssessmentInvitations.length === 1
            ? formatAssessmentInvitationTexts(contextState.acceptedAssessmentInvitations[0])
            : t('ACCEPT_MULTIPLE_ASSESSMENT_INVITATIONS_MODAL_TITLE')
        }
        setShow={handleCloseAssessmentModal}
        content={
          contextState.acceptedAssessmentInvitations.length > 1 && (
            <ul className='text-left pt-2'>
              {contextState.acceptedAssessmentInvitations.map((invitation, index) => (
                <li className='my-1' key={index}>
                  {formatAssessmentInvitationTexts(invitation)}
                </li>
              ))}
            </ul>
          )
        }
        onOkText={t('ACCEPT_ASSESSMENT_INVITATIONS_MODAL_ACCEPT_BUTTON')}
        onOk={() => handleCloseAssessmentModal(false)}
      />
      <Banner text={t('SIDEBAR_HOME')} />
      <Container>
        <div className={`description-container bg-white dark:bg-dark-bold rounded mx-auto p-4`}>
          <DevProfile />
        </div>
        {!contextState.roles.map(rol => rol).includes(roles.EXCLUSIVEUSER) && (
          <div className='description-container rounded mb-3 flex flex-row column-gap mx-auto'>
            <Tooltip overlayClassName='tooltip-home' title={t('TOOLTIP_HOME_TITLE_CHALLENGES')}>
              <div
                className='flex-1 text-center flex flex-col cursor-pointer button border-first'
                onClick={() => history.push('/inscription')}>
                <spam className='text-base'>{t('HOME_TITLE_CHALLENGES')}</spam>
              </div>
            </Tooltip>
            <Tooltip overlayClassName='tooltip-home' title={t('TOOLTIP_HOME_TITLE_RESOURCES')}>
              <div
                className='flex-1 text-center flex flex-col cursor-pointer button'
                onClick={() => history.push('/resources')}>
                <spam className='text-base'>{t('HOME_TITLE_RESOURCES')}</spam>
              </div>
            </Tooltip>
            <Tooltip overlayClassName='tooltip-home' title={t('TOOLTIP_HOME_TITLE_JOBS')}>
              <div
                className='flex-1 text-center flex flex-col cursor-pointer button border-last'
                onClick={() => history.push('/job-posts')}>
                <spam className='text-base'>{t('HOME_TITLE_JOBS')}</spam>
              </div>
            </Tooltip>
          </div>
        )}
        {!!screenings?.length && (
          <>
            <ScreeningInvitations invitations={screenings} />
            <StartedScreenings invitations={screenings} />
            <FinishedScreenings screenings={screenings.filter(screening => screening.status === 'FINISHED')} />
          </>
        )}
        {!loading ? (
          <div className='max-w-[65rem] m-auto bg-white dark:bg-dark-bold rounded'>
            {screenings.filter(
              screening =>
                screening.status !== 'FINISHED' &&
                screening.status !== 'PROCESSING' &&
                new Date(screening.stage?.endDate).getTime() > new Date().getTime(),
            ).length ? null : !isRole(roles.EXCLUSIVEUSER) ? (
              <>
                <Challenges invitations={screenings} />
                <MyChallenges />
              </>
            ) : null}
          </div>
        ) : (
          <div
            className='description-container bg-white dark:bg-dark-bold rounded mx-auto p-4 mb-3'
            data-testid='home-container-skeleton-div'>
            <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-2'>
              {[1, 2, 3, 4].map(it => (
                <div className='p-0 my-2' key={it}>
                  <div className='bg-white dark:bg-dark-normal pt-3 px-3 pb-1 challenges-cards flex flex-col dark:border-gray-500 border-2 border-alkemy-blue'>
                    <Skeleton.Input
                      active={true}
                      size='small'
                      className='w-24 h-5'
                      data-testid='home-skeleton-input-one'
                    />
                    <Skeleton.Input
                      active={true}
                      size='small'
                      className='w-10 mt-2 h-4'
                      data-testid='home-skeleton-input-two'
                    />
                    <Skeleton.Input
                      active={true}
                      size='small'
                      className='w-14 mt-2 h-4'
                      data-testid='home-skeleton-input-three'
                    />
                    <Skeleton.Input
                      active={true}
                      size='small'
                      className='w-28 mt-2 h-4'
                      data-testid='home-skeleton-input-four'
                    />
                    <Skeleton.Input
                      active={true}
                      size='small'
                      className='w-24 mt-2 h-4'
                      data-testid='home-skeleton-input-five'
                    />
                    <Skeleton.Input
                      active={true}
                      size='small'
                      className='w-24 mt-3 mb-4 h-6 mx-auto'
                      data-testid='home-skeleton-input-six'
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

export default Home;
