import useTranslations from '../../hooks/useTranslations';
import StartedScreeningCard from '../StartedScreeningCard/StartedScreeningCard';

const StartedScreenings = ({ invitations }) => {
  const { t } = useTranslations();

  const unfinishedScreenings = invitations.filter(screening => {
    return screening.status === 'IN_PROGRESS' && new Date(screening.stage.endDate) > new Date().getTime();
  });

  return (
    <>
      {!!unfinishedScreenings.length && (
        <div
          className='description-container bg-white dark:bg-dark-bold rounded mx-auto p-4 mb-3'
          data-testid='start-screening-container-div'>
          <span className='text-base font-bold dark:text-white' data-testid='start-screening-title-span'>
            {t('STARTED_SCREENINGS')}
          </span>
          <div>
            {unfinishedScreenings.map(screening => {
              return <StartedScreeningCard key={screening.id} {...screening} />;
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default StartedScreenings;
