import moment from 'moment';
import useTranslations from '../../hooks/useTranslations';
import { refreshToken } from '../../utils/refreshToken';
import { TrackEvent } from '../../utils/segmentUtils';
import { ActionTypes, useContextState } from '../../redux/contextState';
import { isNullOrUndefined } from '../../utils/typesUtils';
import { userUtcOffset, validateInvitationHasLimitDate, validateInvitationStatus } from '../../utils/calendarUtils';
import { getScreeningInvitation, hideDeletedInvitation } from '../../api/userService';

const StartedScreeningCard = ({
  company,
  stage,
  userId,
  levelId,
  id,
  technologyId,
  companyId,
  workspaceId,
  limitDate,
  deletedByPartner,
}) => {
  const { t } = useTranslations();
  const { startDate, endDate } = stage;
  const { contextState, setContextState } = useContextState();
  const momentStartDate = moment(startDate).utc(userUtcOffset());
  const momentEndDate = moment(
    new Date(limitDate) < new Date(endDate) && !isNullOrUndefined(limitDate) ? limitDate : endDate,
  ).utc(userUtcOffset());

  const formattedStartHour = momentStartDate.format('HH:mm');
  const formattedEndHour = momentEndDate.format('HH:mm');

  const startDay = momentStartDate.format('DD/MM/Y');
  const endDay = momentEndDate.format('DD/MM/Y');
  const isButtonDisabled = new Date(startDate).getTime() > new Date().getTime();

  const getScreeningsUrl = async () => {
    const isProctoringEnable = stage?.proctoringEnabled;
    const proctoringKey = window?.location?.hash?.substring(1);
    const redirectURL = new URL(process.env.RAZZLE_RUNTIME_SCREENINGS);
    const token = await refreshToken();
    redirectURL.search = new URLSearchParams({
      token: token,
      userId: userId,
      isProctoringEnable: isProctoringEnable,
    });
    if (levelId) {
      redirectURL.searchParams.append('levelId', levelId);
    }
    if (id) {
      redirectURL.searchParams.append('invitationId', id);
    }
    if (technologyId) {
      redirectURL.searchParams.append('technologyId', technologyId);
    } else {
      redirectURL.searchParams.append('companyId', companyId);
      redirectURL.searchParams.append('workspaceId', workspaceId);
    }
    if (proctoringKey) {
      redirectURL.hash = `#${proctoringKey}`;
    }
    return redirectURL;
  };

  return (
    <div
      className='w-full bg-white dark:bg-dark-normal my-4 border-2 rounded-lg border-alkemy-blue p-3'
      data-testid='screening-card-container-div'>
      <div className='flex w-full justify-between items-center'>
        <span className='italic dark:text-white' data-testid='screening-card-title-span'>
          {company?.displayName} {t('SCREENINGS_IS_EVALUATING_YOU_IN')}:
        </span>
        <img src={company?.picture} className='w-[45px] h-auto ml-2.5' data-testid='screening-card-company-img' />
      </div>
      <div className='font-medium text-sm text-grey'>
        <span
          className='inline-block mb-4 text-alkemy-black text-sm font-semibold dark:text-white'
          data-testid='screening-card-name-span'>
          {stage.recruitSession.name}
        </span>
        {!stage.emptyDates && (
          <p className='block dark:text-white' data-testid='screening-card-start-date-p'>
            {`${t('SCREENINGS_INVITATION_FROM')} `}
            <span className='text-purple' data-testid='screening-card-start-date-span'>
              {startDay} {`${t('SCREENINGS_INVITATION_AT_START_END')} `} {formattedStartHour}
            </span>
          </p>
        )}
        {deletedByPartner ? (
          <div>
            <p
              className='block text-xs text-black dark:text-white'
              data-testid='invitations-cards-started-delete-invitation-p'>
              {t('DELETED_SCREENING_INVITATION')}
            </p>
          </div>
        ) : validateInvitationStatus(endDate, limitDate) ? (
          <div>
            <p className='block text-xs text-black dark:text-white'>{t('LIMIT_DATE_FINISHED_SCREENING_INVITATION')}</p>
          </div>
        ) : (
          validateInvitationHasLimitDate(endDate, limitDate) && (
            <p className='block dark:text-white' data-testid='screening-card-end-date-p'>
              {`${t('SCREENINGS_INVITATION_UNTIL')} `}
              <span className='text-purple' data-testid='screening-card-end-date-span'>
                {endDay} {`${t('SCREENINGS_INVITATION_AT_START_END')} `}
                {formattedEndHour}
              </span>
            </p>
          )
        )}
        <div className='w-full flex justify-end items-end'>
          {deletedByPartner || validateInvitationStatus(endDate, limitDate) ? (
            <button
              onClick={() => {
                hideDeletedInvitation({ id })
                  .then(async () => {
                    getScreeningInvitation().then(res => {
                      setContextState({
                        type: ActionTypes.SetScreenings,
                        value: res,
                      });
                    });
                  })
                  .catch(() => {});
              }}
              disabled={isButtonDisabled}
              className='w-fit px-5 py-1 m-auto mt-5 bg-alkemy-blue font-bold rounded-2xl text-white hover:cursor-pointer'
              data-testid='invitations-cards-delete-invitation-btn'>
              {t('DELETED_SCREENING_INVITATION_DO_NOT_SHOW')}
            </button>
          ) : (
            <button
              onClick={async () => {
                TrackEvent('screening-start', { userId });
                const redirectUrl = await getScreeningsUrl();
                window.location.href = redirectUrl.toString();
              }}
              className='w-fit px-5 py-1 mt-4 bg-maya-blue font-bold rounded-2xl text-white hover:cursor-pointer'
              data-testid='screening-card-start-evaluation-button'>
              {t('CONTINUE_SCREENING')}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default StartedScreeningCard;
